.paginations {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

/* Page link styling */
.pages-items-containers {
  background-color: #dde6ed;
  margin: 0 5px;
  padding: 5px 15px;
  border: 0px solid #ccc;
  border-radius: 5px;
  text-decoration: none;
  color: #474e68;
}

.pages-links-labels {
  color: #474e68;
  font-weight: bold;
  font-size: 18px;
}

.pages-items-containers:hover {
  background-color: #b9eddd;
}

.pages-links-labels:hover {
  color: #000000;
}

.actives:hover {
  background-color: #b9eddd;
  text-decoration: none;
  color: #000000;
}

.activess:hover {
  color: rgb(0, 0, 0);
}

.activess {
  color: rgb(255, 255, 255);
}

.actives {
  background-color: #577d86;
  border: 0px solid #ccc;
  border-radius: 5px;
  text-decoration: none;
}
